<template>
  <div class="case">
    方案
  </div>
</template>

<script>
export default {
  name: "case"
}
</script>

<style scoped>
@import url('../assets/css/case.css');
</style>